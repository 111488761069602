<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">แก้ไขแจ้งงาน เบิกโครงการ/กิจกรรม</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3 pb-15">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>เรื่อง</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="topic"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="topic"
                                    maxlength="150"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ประเภทงาน</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="jobType"
                                rules="required"
                            >
                              <v-select
                                  :items="jobTypeItems"
                                  v-model="jobType"
                                  item-text="type_name"
                                  item-value="id"
                                  :error-messages="errors"
                                  dense
                                  outlined
                              ></v-select>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ปีงบประมาณ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fiscalyear"
                                rules="required"
                            >
                              <v-select
                                  :items="fiscalyearItems"
                                  v-model="fiscalyear"
                                  :error-messages="errors"
                                  dense
                                  outlined
                                  @change="changeYear()"
                              ></v-select>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>โครงการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fa"
                                rules="required"
                            >
                              <v-autocomplete
                                v-model="fa"
                                :items="faItems"
                                :filter="customFilter"
                                :error-messages="errors"
                                @change="changeFA()"
                                item-value="fa"
                                dense
                                outlined
                              >
                                <template v-slot:selection="{ item }">
                                  <span>{{ item.fa }} {{ item.fa_text }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.fa }} {{ item.fa_text }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>

                              </v-autocomplete>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>กิจกรรม</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fp"
                                rules="required"
                            >
                              <v-select
                                v-model="fp"
                                :items="fpItems"
                                :error-messages="errors"
                                dense
                                outlined
                                item-value="fp"
                                @change="changeFP()"
                              >
                                <template v-slot:selection="{ item }">
                                  <span>{{ item.fp }} {{ item.fp_text }}</span>
                                </template>
                                <template v-slot:item="{ item }">
                                  <v-list-item-content>
                                    <v-list-item-title>{{ item.fp }} {{ item.fp_text }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>

                              </v-select>
                            </validation-provider>
                            <div class="text-h6">งบประมาณคงเหลือ {{ displayNumberFormat(budget.budget - budget.spend) }} บาท</div>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                          <v-subheader>รายการ</v-subheader>
                      </v-col>
                      <v-col cols="5">
                        <v-card outlined>
                          <v-list>
                            <v-list-item v-for="(item, i) in budgetItems" :key="i">
                              <v-list-item-content>
                                <v-list-item-title>{{ item.ci_text }}</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="3">
                          <v-subheader>จำนวนเงินเบิก</v-subheader>
                      </v-col>
                      <v-col cols="5">
                        <validation-provider
                            v-slot="{ errors }"
                            name="budgetPrice"
                            rules="required|decimal"
                        >
                            <v-text-field
                                v-model="budgetPrice"
                                outlined
                                dense
                                :error-messages="errors"
                                required
                            ></v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col cols="3">
                            <v-subheader>วันที่กำหนดเสร็จงาน</v-subheader>
                        </v-col>
                        <v-col cols="5">
                          <v-menu
                              v-model="menu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                          >
                              <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                      v-model="dueDate"
                                      label="วันที่"
                                      prepend-icon="mdi-calendar"
                                      readonly
                                      v-bind="attrs"
                                      v-on="on"
                                      outlined
                                      dense
                                  ></v-text-field>
                              </template>
                              <v-date-picker
                                  v-model="dueDate"
                                  locale="th-th"
                                  @input="menu = false"
                              ></v-date-picker>
                          </v-menu>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>รายละเอียด</v-subheader>
                        </v-col>
                        <v-col cols="5">
                          <vue-editor v-model="description" :editor-toolbar="customToolbar" />
                          <!-- <v-textarea
                            v-model="description"
                            auto-grow
                            outlined
                            rows="8"
                          ></v-textarea> -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>แนบลิ้งค์ไฟล์เอกสาร</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <v-text-field
                                v-model="linkFile"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                            <v-text-field
                                v-model="linkFile2"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                            <v-text-field
                                v-model="linkFile3"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                            <v-text-field
                                v-model="linkFile4"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                            <v-text-field
                                v-model="linkFile5"
                                outlined
                                dense
                                placeholder="ตัวอย่าง https://docs.google.com/spreadsheets/d/1o6hy-"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-divider class="mx-4 mb-5" />
                    <v-row>
                        <v-col>
                            <v-btn depressed color="success" @click="save()">
                                บันทึก
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";
import { VueEditor } from "vue2-editor";
import numeral from 'numeral'

export default {
    props: ['jobId'],
    components: { VueEditor },
    data: () => ({
        storeUser: null,
        topic: 'เบิกโครงการ/กิจกรรม',
        dueDate: new Date().toISOString().substr(0, 10),
        description: '',
        linkFile: '',
        linkFile2: '',
        linkFile3: '',
        linkFile4: '',
        linkFile5: '',
        jobType: null,
        jobTypeItems: [],
        formChkErrors: false,
        menu: false,
        fiscalyear: null,
        fiscalyearItems: [],
        fa: null,
        fp: null,
        fpItems: [],
        faItems: [],
        budget: {},
        budgetItems: [],
        customToolbar: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
        budgetPrice: 0
    }),
    created() {
        this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getJobType()
      var startYear = new Date().getFullYear() + 5
      var endYear = 2000
      for (let index = startYear; index >= endYear; index--) {
        this.fiscalyearItems.push(index)
      }
      await this.getData()
      await this.getDataJobProject()
      this.$store.commit('setLoading', false)
    },
    methods: {
        displayNumberFormat(num){
          return numeral(num).format('0,0.00')
        },
        async getJobType() {
            try {
                const res = (await Api().get("/jobroottype/3")).data;

                if (res.status == 'success') {
                    this.jobTypeItems = res.data
                    if(this.jobTypeItems && this.jobTypeItems.length > 0){
                        this.jobType = this.jobTypeItems[0].id
                    }
                } else {
                    console.log(res.data);
                }
            } catch (err) {
                console.log(err.message);
            }
        },
        async getData() {
          try {
            const res = (await Api().get('jobrequest/' + this.jobId)).data;

            if (res.status == 'success') {
              this.topic = res.data.topic
              this.jobType = res.data.job_type
              this.dueDate = res.data.due_date
              this.description = res.data.description
              this.linkFile = res.data.link_file
              this.linkFile2 = res.data.link_file2
              this.linkFile3 = res.data.link_file3
              this.linkFile4 = res.data.link_file4
              this.linkFile5 = res.data.link_file5
            } else {
              console.log(res.data);
            }

          } catch (err) {
            console.log(err.message);
          }
        },
        async getDataJobProject() {
          try {
            const res = (await Api().get('jobrequestproject/' + this.jobId)).data;

            if (res.status == 'success') {
                console.log(res.data)
                this.fiscalyear = res.data.fiscal_year
                await this.changeYear()
                this.fa = res.data.fa
                await this.changeFA()
                this.fp = res.data.fp
                await this.getFPItems()
                this.budgetPrice = res.data.budget
            } else {
              console.log(res.data);
            }

          } catch (err) {
            console.log(err.message);
          }
        },
        async changeYear() {
            this.$store.commit('setLoading', true)
            this.fa = null
            this.getFA()
            this.budgetData = []
            this.$store.commit('setLoading', false)
        },
        async changeFA() {
          this.$store.commit('setLoading', true)
          this.fp = null
          this.getFP()
          this.$store.commit('setLoading', false)
        },
        async changeFP() {
          this.$store.commit('setLoading', true)
          await this.getFPItems()
          this.$store.commit('setLoading', false)
        },
        customFilter (item, queryText, itemText) {
            console.log(itemText)
            const textOne = item.fa.toLowerCase()
            const textTwo = item.fa_text.toLowerCase()
            const searchText = queryText.toLowerCase()

            return textOne.indexOf(searchText) > -1 ||
            textTwo.indexOf(searchText) > -1
        },
        async getFA(){
            try {
            const req = {
                params: {
                    fiscal_year: this.fiscalyear
                }
            }

            const res = (await Api().get("fa", req)).data;

            if (res.status == 'success') {
                this.faItems = res.data
            } else {
                console.log(res.data);
            }
            } catch (err) {
                console.log(err.message);
            }
        },
        async getFP() {
            this.$store.commit('setLoading', true)
            try {
                const req = {
                    params: {
                      fiscal_year: this.fiscalyear,
                      fa: this.fa
                    }
                }

                const res = (await Api().get("fp", req)).data;

                if (res.status == 'success') {
                    this.fpItems = res.data
                } else {
                    console.log(res.data);
                }
            } catch (err) {
                console.log(err.message);
            }
            this.$store.commit('setLoading', false)
        },
        async getFPItems() {
          this.$store.commit('setLoading', true)
          try {
              const req = {
                  params: {
                    fiscal_year: this.fiscalyear,
                    fa: this.fa,
                    fp: this.fp
                  }
              }

              const res = (await Api().get("budgetitem", req)).data;

              if (res.status == 'success') {
                  // this.fpItems = res.data
                  console.log(res.data)
                  this.budgetItems = res.data.fpItems
                  this.budget = res.data.budget
              } else {
                  console.log(res.data);
              }
          } catch (err) {
              console.log(err.message);
          }
          this.$store.commit('setLoading', false)
        },
        async save() {
            this.$store.commit('setLoading', true)
            const req = {
                topic: this.topic,
                job_type: this.jobType,
                due_date: this.dueDate,
                description: this.description,
                link_file: this.linkFile,
                link_file2: this.linkFile2,
                link_file3: this.linkFile3,
                link_file4: this.linkFile4,
                link_file5: this.linkFile5,
                request_by: this.storeUser.id,
                fiscal_year: this.fiscalyear,
                fa: this.fa,
                fp: this.fp,
                budget: this.budgetPrice
            }

            this.formChkErrors = false
            this.formChkErrors = await this.$refs.form.validate()

            if(this.formChkErrors){
              let res = (await Api().put("jobrequestproject/" + this.jobId, req)).data;

              if (res.status == 'success') {
                  this.$store.commit('setLoading', false)
                  await this.$refs.confirm.open('ข้อความ', 'บันทึกข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
                  this.$router.push('/request/list')
              }else{
                  this.$store.commit('setLoading', false)
              }
            }else{
                this.$store.commit('setLoading', false)
            }
        }
    }
};
</script>